
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import ApiLogistics from "@/request/apis/api_logistics";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import PageUtil from "@/utils/page_util";
import {ICommentItem} from "@/models/logistics_model";

interface IState {
  list: ICommentItem[],
  total: number;
  loading: boolean;
  searches: any
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '订单编号',
    dataIndex: 'consignment',
    key: 'sn',
  },
  {
    title: '评论用户',
    dataIndex: 'consignment',
    key: 'user',
  },
  {
    title: '评分',
    dataIndex: 'score',
    key: 'score',
  },
  {
    title: '评价内容',
    dataIndex: 'content',
    key: 'content',
  },
  {
    title: '标签',
    dataIndex: 'tags',
    key: 'tags',
  },
  {
    title: '评论时间',
    dataIndex: 'create_time',
    key: 'create_time',
  },
  {
    title: '是否显示',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '操作',
    key: 'action',
  },
];

export default defineComponent({
  name: 'ComplaintList',
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      searches: {
        page_no: PageUtil.pageNo,
        page_size: PageUtil.pageSize,
      },
    })

    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page_no,
      pageSize: state.searches.page_size,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.page_size = pages.pageSize
      state.searches.page_no = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      let params = {...state.searches}
      StorageUtil.setParams(params)
      state.loading = true
      const res = await ApiLogistics.getUserCommentList(params)
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.count || 0
        state.list = res.data?.list || []
      }
    }

    // 删除
    const onDel = async (item: ICommentItem) => {
      state.loading = true
      const res = await ApiLogistics.delComment(item.id)
      state.loading = false
      message.destroy()
      if (res && res.error_code === 0) {
        message.success('删除成功')
        await getList()
      } else {
        message.error(res?.msg || '删除失败');
      }
    }

    // 显示隐藏
    const setStatus = async (item: ICommentItem) => {
      state.loading = true
      const res = await ApiLogistics.setCommentStatus(item.id, item.status === 0 ? 1 : 0)
      message.destroy()
      state.loading = false
      if (res && res.error_code === 0) {
        message.success('设置成功')
        await getList()
      } else {
        message.error(res?.msg || '设置失败');
      }
    }

    // 去详情
    const goDetail = (item: ICommentItem, type: 'comment' | 'order' = 'comment') => {
      if(type === 'comment'){
        // 去详情
        router.push({path: '/comment/detail', query: {id: item.id}}).then()
      }else{
        // 去订单详情
        router.push({path: '/logistics_order/order_details', query: {id: item.consignment?.id}}).then()
      }
    }

    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      state.searches[key] = value
      state.searches.page_no = 1
      state.searches.page_size = PageUtil.pageSize
      getList()
    }

    // 重置search参数
    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page_no = 1
      state.searches.page_size = PageUtil.pageSize
      getList()
    }

    // 初始化search参数
    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      pagination,
      handleTableChange,
      onSearch,
      resetSearch,
      goDetail,
      onDel,
      setStatus
    }
  }
})
